import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#b0380d',
        secondary: '#3f3f3f',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#d9d9d9',
        success: '#4CAF50',
        warning: '#fec200'
      },
    },
  },
});
