import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Sell from '../views/Sell.vue'
import QRCode from '../views/QRCode.vue'
import Test from '../views/Test.vue'
import Test2 from '../views/Test2.vue'
import Pay from '../views/Pay.vue'
import Checkout from '../views/Checkout.vue'
import Result from '../views/Result.vue'
import Splash from '../views/Splash.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Inicio',
    component: Home,
    meta: { layout: 'toolbar-layout' }
  },
  {
    path: '/sell',
    name: 'Sell',
    component: Sell,
    meta: { layout: 'toolbar-layout' }
  },
  {
    path: '/qrcode',
    name: 'QR Code',
    component: QRCode,
    meta: { layout: 'toolbar-layout' }
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: { layout: 'fullscreen-layout' }
  },
  {
    path: '/test2',
    name: 'Test2',
    component: Test2,
    meta: { layout: 'fullscreen-layout' }
  },
  {
    path: '/pay',
    name: 'Payment Page',
    component: Pay,
    meta: { layout: 'fullscreen-layout' }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: { layout: 'fullscreen-layout' }
  },
  {
    path: '/result',
    name: 'Resultado',
    component: Result,
    meta: { layout: 'fullscreen-layout' }
  },
  {
    path: '/splash',
    name: 'Splash',
    component: Splash,
    meta: { layout: 'fullscreen-layout' }
  },  
  {
    path: '/',
    redirect: { name: 'Splash' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
