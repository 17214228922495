<template>
  <v-container>
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Configurar QR</v-toolbar-title>
          </v-toolbar>          
          <v-card-text>
            <v-form>
              <v-text-field v-model="amount" type="tel" :rules="onlyNumbers" label="Nuevo importe" class="mt-10 mb-10 text-h4">
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="apply"
            >
              Aplicar
            </v-btn>
          </v-card-actions>
        </v-card>        
      </v-dialog>
  </v-container>
</template>

<script>
  export default {
    name: 'Modify',
    props: {
      dialog: {
        type: Boolean,
        default: function() { return false }
      }
    },
    data() {
      return {
        onlyNumbers: [
          v => !!v || 'Número requerido'
        ],
        amount: null,
      }
    },
    methods: {
      close: function() {
        this.$emit("wanna-close")
      },
      apply: function() {
        this.$emit("wanna-save", this.amount)
        this.$emit("wanna-close")
      }
    }
  }
</script>

<style>

</style>
