<template>
  <v-container>
    <v-row align-content="center" justify="center">
      <v-col class="keypad">
        <v-banner class="banner">{{ textNumber }} <span style="color: #00897b"> €</span></v-banner>
      </v-col>
    </v-row>
    <v-row align-content="center" justify="center">
      <v-col class="keypad">
        <v-btn 
          depressed 
          large
          height="90" 
          width="90" 
          color="primary" 
          class="key" 
          v-for="key in keys" :key="key.key" 
          @mousedown="keyPressed(key)"
        >
          <div v-if="key.key == 'delete'">
            <v-icon large>mdi-backspace</v-icon>
          </div>
          <div v-else>
            {{ key.key }}
          </div>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    reset: Boolean
  },
  data() {
    return {
      textNumber: '0',
      isDecimal: false,
      keys: [{
        key: '7', 
        type: 'number'
      }, {
        key: '8', 
        type: 'number'
      }, {
        key: '9', 
        type: 'number'
      }, {
        key: '4', 
        type: 'number'
      }, {
        key: '5', 
        type: 'number'
      }, {
        key: '6', 
        type: 'number'
      }, {
        key: '1', 
        type: 'number'
      }, {
        key: '2', 
        type: 'number'
      }, {
        key: '3', 
        type: 'number'
      }, {
        key: 'delete', 
        type: 'delete'
      }, {
        key: '0', 
        type: 'number'
      }, {
        key: ',', 
        type: 'decimal'
      }]
    }
  },
  computed: {
    number: {
      get() {
        let number = parseFloat(this.textNumber.replace(',', '.'))
        number = Math.round(number * 100) / 100
        return number
      }
    }
  },
  watch: {
    reset() {
      this.textNumber = '0'
      this.isDecimal = false
    }
  },
  methods: {
    keyPressed(key) {
      switch(key.type) {
        case 'number': 
          var decimals = this.textNumber.split(',')
          if (decimals.length > 1) {
            if (decimals[1].length >= 2) break
          }
          if (this.textNumber == '0') this.textNumber = ''
          this.textNumber += String(key.key)
        break
        case 'decimal': 
          if (!this.textNumber.toString().includes(',')) {
            this.isDecimal = true
            this.textNumber += ','
          }
        break
        case 'delete' :
          this.textNumber = this.textNumber.substring(0, this.textNumber.length - 1)
        break
      }
      this.$emit('input', this.number)
    }
  }
}
</script>

<style lang="sass">
  .key
    margin: 3px
    font-size: 35px !important

  .keypad
    max-width: 320px
    width: 320px
    align-self: center

  .v-banner
    font-size: 38px
    &__text
      line-height: 35px !important
</style>