import firebase from 'firebase/app'
import 'firebase/database'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDCRNilTgqsKaaRM004TYu6yPkgrg8nC0k",
    authDomain: "prototypes-136a4.firebaseapp.com",
    databaseURL: "https://prototypes-136a4-default-rtdb.firebaseio.com",
    projectId: "prototypes-136a4",
    storageBucket: "prototypes-136a4.appspot.com",
    messagingSenderId: "524693566317",
    appId: "1:524693566317:web:04dbba665947454bcba064",
    measurementId: "G-V03RX63K2P"
  };

firebase.initializeApp(firebaseConfig);

var database = firebase.database()


export { database }