<template>
  <v-container style="min-height: 80%">
    <v-row align-content="center" justify="center" style="min-height: 80%">
      <v-col cols="12" sm="7">
        <Keyboard v-model="number" :reset="reset" />
      </v-col>
      <v-col cols="12" sm="5" class="checkoutButtons">
        <v-btn x-large color="secondary" @click="saleRequest()">
          <v-icon left class="mr-3">mdi-currency-eur</v-icon>
          Pagar
        </v-btn>
        <v-btn @click="resetValue()" x-large color="secondary" class="mt-5">
          <v-icon left>mdi-progress-clock</v-icon>
          Borrar
        </v-btn>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12">
        <div class="console">
          <p>Información de la operación</p>
        </div>
      </v-col>      
    </v-row> -->
    <v-overlay
      v-model="dialog"
      hide-overlay
      persistent
      fullscreen
      width="300"
    >
      <v-card
        color="primary"
        dark
        width="1000"    
      >
        <v-card-text class="font-weight-black wait">
          Por favor, espere
          <p><v-icon x-large>mdi-clock-time-nine-outline</v-icon></p>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
          <v-btn @click="cancelWait()" x-large color="secondary" class="mt-5">
          <v-icon left>mdi-delete</v-icon>
          Cancelar
        </v-btn>

        </v-card-text>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
import Keyboard from '../components/globals/Keyboard'
import { mapState, mapActions } from 'vuex'
import {database as db} from '../configuration/firebaseAdmin'

export default {
  components: {
    Keyboard
  },
  data() {
    return {
      number: 0,
      reset: false,
      numTicket: '',
      dialog: false,
    }
  },
  title () {
    return `TPV Demo`
  },
  computed: {
    ...mapState(['configuration'])
  },
  methods: {
    ...mapActions(['setNumTicket']),  
    resetValue() {
      this.reset = !this.reset
    },
    cancelWait() {
      this.dialog = false
    },
    async saleRequest() {
      this.dialog = true
      this.numTicket = "TICKET-" + String(Math.random()).substring(0, 8).replace('.', '').replace(',', '')
      this.setNumTicket(this.numTicket)
      db.ref('qrdata').set({
          amount: this.number,
          read: false
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.checkoutButtons
  display: flex
  flex-direction: column
  justify-content: center  
  align-items: center
  button
    width: 300px
  @media (min-width: 600px)
    align-items: start
    button
      width: 230px

.console
  background-color: black
  border: 3px solid #3f3f3f
  color: #d9d9d9
  text-align: center

.wait
  font-size: 1em
  border: 15px solid #3f3f3f

</style>