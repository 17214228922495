<template>
  <v-container style="min-height: 80%">
    <v-row>
        <v-col cols="12">
        <div class="pa-0 ma-0">
            <vue-qr class="qr-image elevation-24" text="https://qrdemo.web.app/pay" :size="300" v-if="QRData.amount!=0"></vue-qr>
            <v-progress-circular :size="200" :width="20" color="primary" indeterminate v-if="QRData.amount == 0"></v-progress-circular>
        </div>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" class="pa-0">
        <div class="pa-5 ma-5 mt-0 pt-0">
            <p class="warn">Escanea el código QR para pagar</p>
            <p class="price rounded-lg" v-if="QRData.amount != 0">
              {{ formatAmount(QRData.amount) }}
            </p>
            <p class="price rounded-lg" v-if="QRData.amount == 0">
              <v-progress-circular :size="50" color="dark" indeterminate></v-progress-circular>
            </p>
        </div>
        </v-col>
    </v-row>
    <v-bottom-navigation app fixed grow background-color="black" color="white" :value="value" height="14vh">
    <v-spacer></v-spacer>
    <v-btn value="new" @click="randomAmount">
        <span>Importe al azar</span>
        <v-icon>mdi-qrcode-plus</v-icon>
    </v-btn>
    <v-btn value="modify" @click="configureQR('modify')">
        <span>Modificar</span>
        <v-icon>mdi-qrcode-edit</v-icon>
    </v-btn>
    <v-btn value="remove" @click="deleteAmount">
        <span>Borrar</span>
        <v-icon>mdi-qrcode-remove</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    </v-bottom-navigation>
    <Modify :dialog="dialog" @wanna-close="closeDialog" @wanna-save="saveAmount"></Modify>
  </v-container>
</template>

<script>
import VueQr from 'vue-qr'
import {database as db} from '../configuration/firebaseAdmin'
import Modify from '@/components/QRCode/Modify'

export default {
  name: 'App',

  components: {
    VueQr,
    Modify
  },
  computed: {
  },
  data: () => ({
    value: "new",
    QRData: { amount: 0 },
    dialog: false
  }),
  firebase: {
    QRData: db.ref('qrdata')
  },
  methods: {
    test: function() {
      console.log('callback call')
    },
    formatAmount: function(amountString) {
      var formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
      return formatter.format(amountString);
    },
    configureQR: function() {
      this.dialog = true
    },
    closeDialog: function() {
      this.dialog = false
    },
    randomAmount: function() {
      let amount = Math.floor(Math.random() * 101)
      db.ref('qrdata').set({
                amount: amount,
                read: false
            })
    },
    deleteAmount: function() {
      db.ref('qrdata').set({
                amount: 0,
                read: false
            })
    },
    saveAmount: function(amount) {
      db.ref('qrdata').set({
                amount: amount,
                read: false
            })
    },
  },
  title () {
    return `QR Demo`
  },
};
</script>

<style scoped>
body {
  margin: 0 0;
  padding: 0 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.footer {
  font-size: 0.5;
  font-weight: bolder;
}

.warn {
  font-size: 1.5em;
}

header {
  background-color: #000000 !important;
}

.price {
  font-size: 4em;
  font-weight: bolder;
  background-color: #3f3f3f;
  color: white;
}
#shopcart-list li {
  list-style: none;
  list-style-type: none;
  font-size: 1.2em;
}

#shopcart-list table {
  border: 1px solid black;
  padding: 25px;
}

.v-btn:not(.v-btn--active) {
  color: black !important;
  background-color: grey !important;
}

.qr-image {
    border: 2px solid #3f3f3f;
}

</style>

