<template>
    <v-app id="fullscreen">
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
  name: 'fullscreen-layout',
}
</script>
