<template>
  <component :is="this.$route.meta.layout ||'div'">
    <router-view />
  </component>
</template>

<script>
  import FullscreenLayout from './layouts/fullscreen'
  import ToolbarLayout from './layouts/toolbar'
  // import {mapState} from 'vuex'

  export default {
    name: 'App',
    components: {
      'toolbar-layout': ToolbarLayout,
      'fullscreen-layout': FullscreenLayout
      },
    // computed: mapState(['layout']),
    data() {
      return {
        layout: 'div'
      }
    },
    mounted() {
    },
    title () {
    return `BidiPay`
    },
  }
</script>
