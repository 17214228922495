<template>
  <v-container fill-height id="splash-home">
  </v-container>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  title () {
    return `Inicio`
  },
}
</script>

<style>
/* .v-main {
  background-image: linear-gradient(#ed6f1b2a, #b0380d48);
  background: url('../assets/background.jpg') center no-repeat;
  background-size: contain;
} */

#splash-home {
  background: url('../assets/logo_bidipay.png') center no-repeat;
  background-size: contain;
}

.logo-texto {
  background-color: white;
  border: 1px solid rgb(0, 0, 0);
}
</style>
