<template>
    <v-container id="ticket-container" class="pa-0 ma-0"  fill-height>
        <v-card width="100vw" height="100vh" flat tiled>
            <v-card-title class="v-card-background pa-1 ma-0">
                <v-container>
                <v-row>
                    <v-col class="align-self-center text-center font-weight-bold text-h4" >
                        <div id="pay-header" style="color: #be863d">Fundación Real Madrid</div>
                    </v-col>
                </v-row>
                </v-container>
            </v-card-title>
            <v-card-text class="pa-0">
                <v-container fluid>
                    <v-row class="ticket-background">
                        <v-col>                    
                            <v-card color="#f3f3f3" elevation="0" >
                              <v-card-title class="justify-center" v-if="loading">
                                <v-progress-circular v-if="loading"
                                        :size="120"
                                        :width="7"
                                        color="#E6CE13"
                                        indeterminate
                                        class="mt-0"
                                        ></v-progress-circular>
                                </v-card-title>
                                <v-card-title class="justify-center" v-if="loading">
                                  <p v-if="loading" class="mt-5 font-weight-black" style="color: #E6CE13">Por favor espere ...</p>
                                </v-card-title>
                                <v-card-title class="justify-center" v-if="!loading">
                                    <v-icon style="font-size: 5em" color="light-green darken-4" v-if="!error">mdi-check-circle-outline</v-icon>
                                    <v-icon style="font-size: 5em" color="red" v-if="error">mdi-close-circle-outline</v-icon>                                    
                                </v-card-title>
                                <v-card-title class="justify-center" v-if="!loading">                                  
                                    <p class="text-center pa-1 font-weight-bold light-green--text text--darken-4" v-if="!error">OPERACIÓN ACEPTADA</p>
                                    <p class="text-center pa-1 font-weight-bold red--text" v-if="error">OPERACIÓN DENEGADA</p>
                                    <v-container class="pa-0 text-center ma-0" width="100wh">
                                        <v-simple-table  id="ticket-table" v-if="!loading">
                                            <tbody>
                                                <tr v-if="!error">
                                                    <td class="text-right font-weight-light">
                                                      <v-btn color="primary" class="ma-1" elevation="4" x-large icon ><v-icon class="font-size: 5em">mdi-receipt</v-icon></v-btn>
                                                    </td>
                                                    <td class="text-left font-weight-light">
                                                      <v-btn color="primary" class="ma-1" elevation="4" x-large icon ><v-icon>mdi-email</v-icon></v-btn>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" class="text-center" v-if="error">
                                                        <v-btn to="/checkout" elevation="2" block x-large class="ma-2 font-weight-bold" color="error" icon outlined rounded><v-icon>mdi-cash-register</v-icon>Reintentar</v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-container>

                                </v-card-title>
                                <v-card-title class="mb-1 justify-center text-h5">
                                    Fundación Real Madrid
                                </v-card-title>
                                <v-card-subtitle>
                                    <div class="text-center">
                                        <span class="text-h6">
                                            Tel.: 641 762 002 <br />
                                            CIF: A56277891
                                        </span>
                                        <p class="ma-0"  v-if="!loading">{{ currentDateTime }}</p>
                                    </div>
                                </v-card-subtitle>
                                <v-card-text>
                                    <v-container class="pa-0 text-center" width="100%">
                                        <v-simple-table class="ma-0 ma-0">
                                            <tbody>
                                                <tr>
                                                    <td class="text-right font-weight-light">Importe: </td>
                                                    <td class="text-left font-weight-light">{{ (QRData.amount).toFixed(2) }} €</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-right font-weight-light">Pedido: </td>
                                                    <td class="text-left  font-weight-light">TICKET-0001</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                        <p class="text-center mt-5 text-h5 font-weight-bold light-green--text text--darken-4" v-if="!error">Gracias por su compra.</p>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-0 ma-0">
                            <v-img id="ticket-image" src="@/assets/recorte-ticket.png" aspect-ratio="3.2" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import {database as db} from '../configuration/firebaseAdmin'

export default {
    firebase: {
        QRData: db.ref('qrdata')
    },
    data: () => ({
        QRData: {amount: 0},
    }),    
    computed: {
        currentDateTime: () => {
            let today = new Date();
            let date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
            let time = today.getHours() + ":" + today.getMinutes();
            let dateTime = date+' '+time;
            return dateTime
        },
        loading() {
            if (this.QRData.amount == 0) {
                return true
            } else {
                return false
            }
        }
    },
    title () {
        return `Real Madrid Fundación`
    },
    created() {
        this.method = this.$route.query.method;
        this.error = this.$route.query.error;
        this.request_id = this.$route.query.request_id;
    }
}
</script>
<style>
/* @font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url(/fonts/Montserrat-Light.ttf) format("truetype");
} */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.v-card-background {
    background-color: #ffffff !important;
}

.ticket-background {
    background-color: #f3f3f3  !important;
    /* border: 5px solid black  !important; */
}

#ticket-table div{
    background-color: #f3f3f3 !important;
}

#ticket-table td {
    border-bottom: 0 !important;
    height: 0;
    padding-top: 5px;
}

.row-separator hr {
    border-top: 1px solid black;
    margin-top: 10px;
}

.row-separator {
    height: 0px !important;
}

#ticket-image {
    top: -50px;
}

#ticket-container, #ticket-container div, #ticket-container td, #ticket-container button {
    font-family: "Montserrat", "Roboto"  !important;
}

#pay-header {
    /* border: 5px solid black; */
    background: #ffffff url('~@/assets/logo_company.png') no-repeat;
    /* background: #E6CE13 url('https://picsum.photos/seed/picsum/400/200') no-repeat; */
    background-size: contain;    
}

</style>