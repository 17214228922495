import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import router from './router'
import store from './store'
import titleMixin from './mixins/titleMixin'
import { rtdbPlugin } from 'vuefire'
import './registerServiceWorker'

Vue.config.productionTip = false
Vue.mixin(titleMixin)
// Vue.mixin({
//   created() {
//     console.log('[created] ' + this.$options.name)
//   }
// })

Vue.use(VueAxios, axios)
Vue.use(rtdbPlugin)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  // created() {
  //   if (this.$route.name != 'Inicio')
  //     this.$router.push('/home')
  // }
}).$mount('#app1')

