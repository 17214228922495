<template>
    <v-container id="ticket-container" class="pa-0 ma-0"  fill-height>
        <v-card width="100vh" height="100vh" flat tiled>
            <v-card-title class="v-card-background pa-1 ma-0">
                <v-container>
                <v-row>
                    <v-col class="align-self-center text-center font-weight-bold text-h3">
                        taxi
                    </v-col>
                </v-row>
                </v-container>
            </v-card-title>
            <v-card-text class="pa-0">
                <v-container fluid>
                    <v-row class="ticket-background">
                        <v-col>
                            <v-card elevation="0" >
                                <v-card-title class="mb-10 justify-center text-h6">
                                    Selecciona método de pago
                                </v-card-title>
                                <v-card-text>
                                    <v-container class="pa-0 text-center" width="100%">
                                        <div id="app"></div>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="pa-0" height="100%">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-img class="mx-auto" src="@/assets/powered-gris.png" contain width="150" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import {database as db} from '../configuration/firebaseAdmin'

export default {
  name: 'Test',
  created () {
  },
  firebase: {
    QRData: db.ref('qrdata')
  },
  data: () => ({
    QRData: {amount: 0},
    loadControl: false
  }),
  mounted() {
      // Control only one load
    //   if (!this.$store.state.pwallLoadControl) {
        // this.$store.commit('SET_PWALL_LOAD', true)
        let pwallScript = document.createElement('script')
        pwallScript.setAttribute('src','https://staging.sipay.es/pwall_sdk/pwall_sdk.bundle.js')

        pwallScript.onload = () => {
            let pwallRender = document.createElement('script')
            pwallRender.setAttribute('src', 'https://staging.sipay.es/pwall_app/js/app.js')
            // pwallRender.dataset.amount = this.QRData.amount * 100
            pwallRender.dataset.amount = this.QRData.amount * 100
            pwallRender.dataset.url = 'https://sandbox.tux-es.com/api/waiap/actions/10'
            pwallRender.dataset.placeholder = '#app'
            pwallRender.dataset.currency = 'EUR'
            pwallRender.dataset.timeout = "30000"
            pwallRender.dataset.profile = "default"

            pwallRender.onload = () => {  
                var placeholder=document.getElementById('app')
                window.PaymentWall.listenTo(placeholder, 'payment_wall_drawn', this.cleanPwall)
                window.PaymentWall.listenTo(placeholder, 'payment_wall_loaded', this.loadedPwall)
                window.PaymentWall.listenTo(document, 'payment_wall_setup', this.setupPwall)
                window.PaymentWall.listenTo(placeholder, 'payment_wall_payment_ko', this.koPwall)
                window.PaymentWall.start()
                document.querySelector('#app header').remove()
            }
            document.head.appendChild(pwallRender)
        }
        document.head.appendChild(pwallScript)

    //   }
  },
  methods: {
      cleanPwall: () => {
        //document.querySelector("#app section header").remove()
        console.log('pintado')
      },
      setupPwall: () => {
          console.log('setup run!')
      },
      loadedPwall: () => {
          console.log('loaded pwall')
      },
      koPwall: () => {
          console.log('pwall ko')
      },
      formatAmount: function(amountString) {
      var formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
      });
      return formatter.format(amountString);
    },
  },
  title () {
    return `Pagar con BidiPay`
  },
}
</script>

<style>
@import 'https://staging.sipay.es/pwall_app/css/app.css';
/* @font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url(/fonts/Montserrat-Light.ttf) format("truetype");
} */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.v-card-background {
    background-color: #E6CE13 !important;
}

#ticket-container, #ticket-container div, #ticket-container td, #ticket-container button {
    font-family: "Montserrat", "Roboto"  !important;
}

</style>