<template>
    <v-container id="ticket-container" class="pa-0 ma-0"  fill-height>
        <v-card width="100vw" height="100vh" flat tiled>
            <v-card-title class="v-card-background pa-1 ma-0">
                <v-container>
                <v-row>
                    <v-col class="align-self-center text-center font-weight-bold text-h4" >
                        <div id="pay-header" style="color: #be863d">Real Madrid</div>
                    </v-col>
                </v-row>
                </v-container>
            </v-card-title>
            <v-card-text class="pa-0">
                <v-container fluid>
                    <v-row class="ticket-background">
                        <v-col>
                            <v-card color="#f3f3f3" elevation="0" >
                                <v-card-title class="mb-1 justify-center text-h5">
                                    Fundación Real Madrid
                                </v-card-title>
                                <v-card-subtitle>
                                    <div class="text-center">
                                        <span class="text-h6">
                                            Tel.: 000 000 000 <br />
                                            CIF: A56277891
                                        </span>
                                        <p class="ma-0"  v-if="!loading">{{ currentDateTime }}</p>
                                    </div>
                                </v-card-subtitle>
                                <v-card-text>
                                    <v-container class="pa-0 text-center" width="100%">
                                        <v-simple-table id="ticket-table" v-if="!loading">
                                            <tbody>
                                                <tr>
                                                    <td class="text-left text-h5 font-weight-light">Donación:</td>
                                                    <td class="text-right text-h5 font-weight-light">{{ QRData.amount }} €</td>
                                                </tr>
                                                <tr>
                                                    <td class="row-separator" colspan="2"><hr /></td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left text-h4">Total</td>
                                                    <td class="text-right text-h4 font-weight-black">{{ QRData.amount }} €</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                        <v-progress-circular v-if="loading"
                                        :size="70"
                                        :width="7"
                                        color="#E6CE13"
                                        indeterminate
                                        class="mt-10"
                                        ></v-progress-circular>
                                        <p v-if="loading" class="mt-5 font-weight-black" style="color: #E6CE13">Por favor espere ...</p>                                     
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-0 ma-0">
                            <v-img id="ticket-image" src="@/assets/recorte-ticket.png" aspect-ratio="3.2" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="pa-0" height="100%">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-btn block elevation="5" color="#be863d" height="10vh" class="text-none text-h6 white--text" x-large rounded bottom to="/checkout" :disabled="loading">
                                Selecciona método de pago
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-img class="mx-auto" src="@/assets/powered-gris.png" contain width="150" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import {database as db} from '../configuration/firebaseAdmin'

export default {
    firebase: {
        QRData: db.ref('qrdata')
    },
    data: () => ({
        QRData: {amount: 0},
    }),    
    computed: {
        currentDateTime: () => {
            let today = new Date();
            let date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
            let time = today.getHours() + ":" + today.getMinutes();
            let dateTime = date+' '+time;
            return dateTime
        },
        loading() {
            if (this.QRData.amount == 0) {
                return true
            } else {
                return false
            }
        }
    },
    title () {
        return `Real Madrid Fundación`
    },

}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.v-card-background {
    background-color: #ffffff !important;
}

.ticket-background {
    background-color: #f3f3f3  !important;
    /* border: 5px solid black  !important; */
}

#ticket-table div{
    background-color: #f3f3f3 !important;
}

#ticket-table td {
    border-bottom: 0 !important;
    height: 0;
    padding-top: 5px;
}

.row-separator hr {
    border-top: 1px solid black;
    margin-top: 10px;
}

.row-separator {
    height: 0px !important;
}

#ticket-image {
    top: -50px;
}

#ticket-container, #ticket-container div, #ticket-container td, #ticket-container button {
    font-family: "Montserrat", "Roboto"  !important;
}

#pay-header {
    /* border: 5px solid black; */
    background: #ffffff url('~@/assets/logo_company.png') no-repeat;
    background-size: contain;    
}

</style>