<template>
  <v-app id="app-container">
    <v-app-bar color="#FFF" fixed max-height="56px" height="56px" app>
      <div>
        <router-link style="text-decoration: none" to="/">
          <v-img src="../assets/logo_sipay.png" max-width="120px" max-height="52px" position="left"></v-img>
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <div class="rightLayer">
        <v-btn icon to="/home">
          <v-icon large>mdi-home</v-icon> 
        </v-btn>
        <v-btn icon to="/qrcode">
          <v-icon large>mdi-qrcode</v-icon> 
        </v-btn>
        <v-btn icon to="/sell">
          <v-icon large>mdi-cash-register</v-icon> 
        </v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <router-view class="content" />
    </v-main>
  </v-app>
</template>

<style>
#app-container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}


.rightLayer a {
  margin-left: 5px;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
  name: 'toolbar-layout',
  computed: {
    ...mapState(['configuration'])
  },
}
</script>
