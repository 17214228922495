
<template>
    <v-container id="contenedor" class="pa-0" fill-height fluid @click="go">
        <v-row class="flex-row fill-height">
            <v-col class="align-self-center" cols="12">
                    <v-img src="@/assets/logo_bidipay.png" contain max-height="400px"  />
            </v-col>
            <v-col class="align-self-center" cols="12">
                    <v-img src="@/assets/logo_bidipay_texto.png" class="mx-5" max-height="100px" contain />
            </v-col>
            <v-col class="align-self-center" cols="12">
                <div class="footer">
                    <v-progress-circular indeterminate color="primary" size="40" width="5"></v-progress-circular>
                </div>
            </v-col>
            <v-col cols="12" class="align-self-center">
                <div class="footer">
                    <v-img src="@/assets/powered_by_sipay3.png" contain max-height="20" />
                    <span>2021 - Copyright for Sipay</span>
                </div>
            </v-col>
        </v-row>
  </v-container>
</template>

<script>

export default {
  beforeMount() {
      
  },  
  components: {
  },
  data() {
      return {
          loading: true
      }
  },
  computed: {
  },
  methods: {
      go() {
          this.$router.push({name: 'Inicio'})
      },
  },
  mounted() {
      setTimeout(() => {
          this.loading = false
          this.go()
      }, 2000)
  },
  title () {
    return `BidiPay`
  },
}
</script>

<style>
.footer {
    font-size: 0.6em;
    color: #ee7017;
    text-align: center;
}

#fullscreen {
    overflow: auto !important;
}

#contenedor {
    border: 0px;
}
</style>
