import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    configuration: {
    },
    layout: 'toolbar-layout',
    ticket: 'unamed',
    pwallLoadControl: false,
  },
  mutations: {
    SET_LAYOUT (state, newLayout) {
      state.layout = newLayout
    },
    SET_TICKET (state, ticket) {
      state.ticket = ticket
    },
    SET_PWALL_LOAD (state, status) {
      state.pwallLoadControl = status
    }
  },
  actions: {
    setNumTicket ({commit}, ticket) {
      commit('SET_TICKET', ticket)
    }
  }
})

export default store

